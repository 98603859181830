import React, { useMemo } from 'react';
import dynamic from 'next/dynamic';
import PromoClubsCrousel from "@/pages/homepage/components/PromoClubsCrousel";
import ThreeStepsSection from "@/pages/homepage/components/ThreeStepsSection";
import MobileAppSection from "@/pages/homepage/components/MobileAppSection";
import Footer from "@/components/reusable/Footer";
import BaseLayout from "@/layouts/base-layout";
import { organizationData } from "@/structureData/organization";
import { webpageData } from "@/structureData/webpage";
import { GetServerSideProps } from 'next';
import booksport from '@/api-routes/booksport';
import AboutUs from "@/pages/homepage/components/AboutUsSection";

interface HomepageProps {
    clubs: Club[];
    error?: string;
    canonicalUrl: string;
}

export interface Club {
    id: string | number;
    slug: string;
    main_card_message?: string;
    main_card_message_bkg_color?: string;
    main_card_message_color?: string;
    filename?: string;
    name: string;
    locationname: string;
    sport: string;
    booking: number;
    review: number;
    nr_review: number;
    address: string;
    elements: string;
    localitate: string;
}

export interface ApiResponseData {
    clubs: Club[];
}

const BackgroundHome = dynamic(() => import('./homepage/components/BackgroundHome'), {
    ssr: false,
});

const Homepage: React.FC<HomepageProps> = ({ clubs, error, canonicalUrl }) => {
    const Map = useMemo(() => dynamic(
        () => import('@/components/maps/MapSection'),
        {
            ssr: false
        }
    ), []);

    // Generare Structured Data
    const orgData = organizationData(canonicalUrl);
    const webPageDataObj = webpageData(
        "Booksport.ro | Rezervă online terenuri sportive și activități",
        "Booksport este prima și cea mai extinsă platformă online pentru rezervări de terenuri sportive, saloane de masaj, mese de ping-pong și multe altele în România. Rezervă rapid și ușor cu plata online.",
        canonicalUrl
    );

    return (
        <BaseLayout
            navbarProps={{ logoW: true, showFilters: false }}
            meta={{
                title: "Booksport.ro | Rezervă online terenuri sportive și activități",
                description: "Booksport este prima și cea mai extinsă platformă online pentru rezervări de terenuri sportive, saloane de masaj, mese de ping-pong și multe altele în România. Rezervă rapid și ușor cu plata online.",
                keywords: "Booksport, rezervare teren, tenis, fotbal, masaj, ping-pong, plata online, sport, România",
                canonical: canonicalUrl,
                ogTitle: "Booksport - Platforma de Rezervări Sportive Online",
                ogDescription: "Descoperă Booksport, prima și cea mai extinsă platformă online pentru rezervări de terenuri sportive și activități conexe în România. Rezervă rapid și ușor terenul preferat!",
                ogImage: "https://www.booksport.ro/images/Logo_H.svg",
                ogType: "website",
                ogSiteName: "Booksport.ro"
            }}
            structuredData={[orgData, webPageDataObj]}
        >
            <div className="page-header home-panel relative h-[70vh]">
                <BackgroundHome />
            </div>
            <PromoClubsCrousel clubs={clubs} error={error} />
            <AboutUs />
            <Map />
            <ThreeStepsSection />
            <MobileAppSection />
            <Footer />
        </BaseLayout>
    );
};

export const getServerSideProps: GetServerSideProps<HomepageProps> = async (context) => {
    const protocol = context.req.headers['x-forwarded-proto'] || 'http';
    const host = context.req.headers['host'];
    const canonicalUrl = `${protocol}://${host}${context.resolvedUrl}`;

    try {
        const res = await booksport.get<ApiResponseData>('/listpromoclubs');
        const data = res.data;

        return {
            props: {
                clubs: data.clubs || [],
                canonicalUrl,
            },
        };
    } catch (error: any) {
        console.error('Error fetching clubs:', error);

        const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch clubs';

        return {
            props: {
                clubs: [],
                error: errorMessage,
                canonicalUrl,
            },
        };
    }
}

export default Homepage;
