import React from 'react';
import Image from "next/image";
import { Typography, Fade, Slide } from "@mui/material";
import useInView from '@/hooks/useInView';

const AboutUs: React.FC = () => {
    const [ref, isInView] = useInView({
        threshold: 0.3,
    });

    return (
        <section ref={ref} className="py-16">
            <div className="container mx-auto">
                <div className="flex flex-col lg:flex-row items-center">
                    <Fade in={isInView} timeout={3000}>
                        <div className="lg:w-1/2 lg:pr-12 mt-8 order-2 lg:order-none">
                            <Typography
                                variant="h2"
                                sx={{
                                    color: '#12803c',
                                    fontWeight: 500,
                                    fontSize: { xs: '22px', md: '34px' },
                                    margin: '20px 0',
                                    fontFamily: 'Poppins, sans-serif',
                                }}
                                className="mb-6"
                            >
                                Cine suntem?
                            </Typography>
                            <p className="text-lg text-gray-700 mb-4">
                                La Booksport, pasiunea pentru sport se îmbină cu tehnologia de vârf pentru a oferi iubitorilor de sport din întreaga țară o experiență de rezervare simplă și eficientă.
                            </p>
                            <p className="text-lg text-gray-700 mb-4">
                                Fondată cu scopul de a facilita accesul la facilitățile sportive din România, Booksport este prima și cea mai extinsă platformă dedicată rezervărilor online de terenuri sportive și activități conexe.
                            </p>
                            <p className="text-lg text-gray-700 mb-6">
                                Sistemul nostru inteligent de rezervări cu plată online permite utilizatorilor să rezerve o sesiune sportivă în mai puțin de 5 minute, garantând disponibilitatea cluburilor și optimizând întregul proces pentru o experiență fără stres.
                            </p>
                        </div>
                    </Fade>

                    <Slide
                        direction="left"
                        in={isInView}
                        timeout={1500}
                        mountOnEnter
                        unmountOnExit
                    >
                        <div className="lg:w-1/2 mt-10 lg:mt-0 flex justify-center order-1 lg:order-none">
                            <Image
                                className="w-full max-w-sm transform transition duration-500 hover:scale-105"
                                src="/images/Logo_H.svg"
                                alt="Booksport - Platforma de rezervări sportive online"
                                width={500}
                                height={500}
                                loading="lazy"
                            />
                        </div>
                    </Slide>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;
