import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import OpenSearch from '../search/OpenSearch';
import ClubFilters from '@/pages/clubs/components/ClubFilters';
import { useAuth } from '@/context/AuthContext';
import {decodeBase64} from "@/utils/base64Utils";

interface NavbarProps {
    className?: string;
    logoW?: boolean;
    showFilters?: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ className, logoW: whiteLogo, showFilters }) => {
    // States for nav interactions/scroll
    const [navOpen, setNavOpen] = useState(false);
    const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>('up');
    const [isScrolled, setIsScrolled] = useState(false);
    const [belowFold, setBelowFold] = useState(false);

    const [dashboardUrl, setDashboardUrl] = useState('');

    // Auth context
    const { token, authInitialized, logout } = useAuth();
    const router = useRouter();

    // Some default images / assets
    const homebg = '/images/login-bk.jpg';
    const logoImg = '/images/logo.svg';
    const whiteLogoImg = '/images/logo-w.svg';

    // Track scroll events
    useEffect(() => {
        let lastScrollPosition = window.scrollY;

        const handleScroll = () => {
            const currentScrollPosition = window.scrollY;
            setIsScrolled(currentScrollPosition > 0);
            setBelowFold(currentScrollPosition > 100);

            // Example logic to hide/show nav on scroll
            const isMobile = window.innerWidth <= 991;
            const disableScrollBehavior = className === 'clubs' && !isMobile;
            if (!disableScrollBehavior) {
                if (currentScrollPosition > lastScrollPosition && currentScrollPosition > 100) {
                    setScrollDirection('down');
                } else if (currentScrollPosition < lastScrollPosition && currentScrollPosition > 100) {
                    setScrollDirection('up');
                }
            }
            lastScrollPosition = currentScrollPosition;
        };

        handleScroll(); // initial call
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [className]);

    // Lock body scroll when mobile nav open
    useEffect(() => {
        document.body.classList.toggle('fixed', navOpen);
    }, [navOpen]);

    // Once auth is initialized, we can decide if the user is auth
    useEffect(() => {
        if (!authInitialized) return;

        if (typeof window !== 'undefined') {
            const user = localStorage.getItem('user');
            if (user && token) {
                try {
                    const decoded = decodeBase64(user);
                    setDashboardUrl(decoded.dashboard_url || '');
                } catch (err) {
                    console.error('Failed to parse user data:', err);
                }
            } else {

                setDashboardUrl('');
            }
        }
    }, [authInitialized, token]);

    // Handle Logout
    const handleLogout = async (e: React.MouseEvent) => {
        e.preventDefault();
        await logout();
        setDashboardUrl('');
        if (router.pathname.includes('dashboard')) {
            await router.replace('/');
        }
    };

    const redirectToLogin = async () => {
        await router.push({
            pathname: '/login',
            query: { from: router.asPath },
        });
    };

    const renderLinks = () => {
        return (
            token ?
                <>
                    <li className="nav-item list-none my-2">
                        <span className="nav-link d-flex items-center" onClick={handleLogout}>
                          <i className="material-icons mr-2">input</i>
                          <p className='my-0'>Deconectare</p>
                        </span>
                    </li>
                    <li className="nav-item list-none my-2">
                        <Link href={`/${dashboardUrl}`} passHref legacyBehavior>
                          <span className="nav-link d-flex items-center">
                            <i className="material-icons mr-2">dashboard</i>
                            <p className='my-0'>Dashboard</p>
                          </span>
                        </Link>
                    </li>
                </> :
                <>
                    <li className="nav-item list-none my-2">
                        <span className="nav-link d-flex items-center" onClick={redirectToLogin}>
                          <i className="material-icons mr-2">input</i>
                          <p className='my-0'>Autentificare</p>
                        </span>
                    </li>
                    <li className="nav-item list-none my-2">
                        <Link href="/register" passHref legacyBehavior>
                          <span className="nav-link d-flex items-center">
                            <i className="material-icons mr-2">assignment_turned_in</i>
                            <p className='my-0'>Inregistrare</p>
                          </span>
                        </Link>
                    </li>
                    <li className="nav-item list-none my-2">
                        <Link href="/register-club" passHref legacyBehavior>
                          <span className="nav-link club-btn d-flex items-center">
                            <i className="material-icons mr-2">add_task</i>
                            <p className='my-0'>Inscrie-ti clubul</p>
                          </span>
                        </Link>
                    </li>
                </>
        )
    }

    return (
        <div>
            {/* Desktop Nav */}
            <div
                className={`navigation-deskt ${className || ''} 
                ${belowFold && scrollDirection === 'down' ? 'slide-up' : ''} 
                ${belowFold && scrollDirection === 'up' ? 'slide-down' : ''} 
                ${belowFold && isScrolled ? 'scrolled' : ''}`}
            >
                <div className="d-flex w-100 nav-wrapper">
                    <Link href="/" legacyBehavior>
                          <Image
                              priority
                              className="logo hover:cursor-pointer"
                              src={
                                  belowFold && isScrolled
                                      ? logoImg
                                      : whiteLogo
                                          ? whiteLogoImg
                                          : logoImg
                              }
                              alt="Logo"
                              width={200}
                              height={50}
                              style={{ width: 'auto' }}
                          />
                    </Link>
                    <div className="social-links d-lg-none mobile-links">
                        <OpenSearch white={belowFold && isScrolled ? false : whiteLogo} />
                    </div>
                    <button
                        className="navbar-toggler"
                        type="button"
                        onClick={() => setNavOpen(!navOpen)}
                    >
                        <span className="sr-only">Toggle navigation</span>
                        <span className="navbar-toggler-icon"> </span>
                        <span className="navbar-toggler-icon"> </span>
                        <span className="navbar-toggler-icon"> </span>
                    </button>
                    { authInitialized &&
                        <ul className="nav">
                            <li className="social-links" style={{ margin: 0 }}>
                                <OpenSearch white={belowFold && isScrolled ? false : whiteLogo} />
                            </li>
                            {renderLinks()}
                        </ul>
                    }
                </div>
                {showFilters && <ClubFilters />}
            </div>

            {/* Mobile Nav Sidebar */}
            <div className={`sidebar nav-mobile ${navOpen ? 'active' : ''}`}>
                <div className="logo">
                    <Link href="/" legacyBehavior>
                        <span className="simple-text logo-normal">
                          <Image
                              className="hover:cursor-pointer"
                              width={200}
                              height={50}
                              src={logoImg}
                              alt="Logo"
                              style={{ width: 'auto' }}
                              priority
                          />
                        </span>
                    </Link>
                </div>
                <div className="sidebar-wrapper">
                    { authInitialized && renderLinks() }
                </div>
                <div className="sidebar-background relative w-full h-full">
                    <Image
                        src={homebg}
                        alt="Sidebar background"
                        fill
                        style={{ objectFit: 'cover' }}
                        priority
                    />
                </div>
            </div>
            <div
                className={`overlay ${navOpen ? '' : 'hidden'}`}
                onClick={() => setNavOpen(!navOpen)}
            ></div>
        </div>
    );
};

export default Navbar;
