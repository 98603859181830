// src/components/reusable/BaseLayout.tsx
import React, { ReactNode } from 'react';
import Head from "next/head";
import Navbar from "@/components/reusable/Navbar";

interface MetaProps {
    title?: string;
    description?: string;
    keywords?: string;
    canonical?: string;
    ogTitle?: string;
    ogDescription?: string;
    ogImage?: string;
    ogType?: string;
    ogUrl?: string;
    ogSiteName?: string;
    twitterCard?: string;
    twitterTitle?: string;
    twitterDescription?: string;
    twitterImage?: string;
    twitterSite?: string;
    twitterCreator?: string;
}

interface LayoutProps {
    children: ReactNode;
    navbarProps?: {
        logoW?: boolean;
        showFilters?: boolean;
        className?: string;
    };
    meta?: MetaProps;
    structuredData?: object[]; // Adăugăm acest prop
}

const BaseLayout = ({
                        children,
                        navbarProps = { logoW: true, showFilters: false, className: '' },
                        meta = {},
                        structuredData = [] // Setăm un default empty array
                    }: LayoutProps) => {
    const {
        title = "Booksport.ro | Rezervă online",
        description = "Booksport este o platformă online pentru închirierea de terenuri de tenis, terenuri de fotbal, saloane de masaj, mese de ping-pong și multe altele, ce permite plata online cu cardul.",
        keywords = "Booksport, tenis, închiriere, masaj, echitație, fotbal, online, ping-pong, plată, card, teren",
        canonical = "",
        ogTitle = "Booksport",
        ogDescription = "Booksport este o platformă online pentru închirierea de terenuri de tenis, terenuri de fotbal, saloane de masaj, mese de ping-pong și multe altele, ce permite plata online cu cardul.",
        ogImage = "/images/Logo_H.svg",
        ogType = "website",
        ogUrl = canonical,
        ogSiteName = "Booksport.ro",
        twitterCard,
        twitterTitle,
        twitterDescription,
        twitterImage,
        twitterSite,
        twitterCreator
    } = meta;

    return (
        <>
            <Head>
                <meta charSet="utf-8" />
                <link rel="icon" href="/favicon.ico" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                {/* Web Manifest */}
                <link rel="manifest" href="/site.webmanifest" />
                <title>{title}</title>
                {canonical && <link rel="canonical" href={canonical} />}
                <meta name="keywords" content={keywords} />
                <meta name="description" content={description} />

                {/* Open Graph Meta Tags */}
                <meta property="og:site_name" content={ogSiteName} />
                <meta property="og:title" content={ogTitle} />
                <meta property="og:description" content={ogDescription} />
                <meta property="og:image" content={ogImage} />
                <meta property="og:type" content={ogType} />
                {ogUrl && <meta property="og:url" content={ogUrl} />}

                {/* Twitter Card Meta Tags */}
                {twitterCard && <meta name="twitter:card" content={twitterCard} />}
                {twitterTitle && <meta name="twitter:title" content={twitterTitle} />}
                {twitterDescription && <meta name="twitter:description" content={twitterDescription} />}
                {twitterImage && <meta name="twitter:image" content={twitterImage} />}
                {twitterSite && <meta name="twitter:site" content={twitterSite} />}
                {twitterCreator && <meta name="twitter:creator" content={twitterCreator} />}

                {/* Injectare Structured Data */}
                {structuredData.map((data, index) => (
                    <script
                        key={index}
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{
                            __html: JSON.stringify(data),
                        }}
                    />
                ))}

                {/* Theme Color */}
                <meta name="theme-color" content="#12803c" />
            </Head>
            <Navbar {...navbarProps} />
            {children}
        </>
    );
}

export default BaseLayout;
