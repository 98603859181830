export const organizationData = (canonicalUrl: string) => ({
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Booksport",
    "logo": "https://www.booksport.ro/images/Logo_H.svg",
    "description": "Booksport este cea mai extinsă platformă online pentru rezervări de terenuri sportive și activități conexe în România.",
    "sameAs": [
        "https://www.facebook.com/Booksport",
        "https://www.instagram.com/Booksport"
    ],
    "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+40770113322",
        "contactType": "customer service",
        "areaServed": "RO",
        "availableLanguage": ["Romanian", "English"]
    },
    "address": {
        "@type": "PostalAddress",
        "streetAddress": "Sos. Mihai Bravu 227 B",
        "addressLocality": "București",
        "addressRegion": "București",
        "postalCode": "030301",
        "addressCountry": "RO"
    },
    "url": canonicalUrl
});
